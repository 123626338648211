<template lang="pug">
  v-dialog(:value="value" @input="updateValue" :max-width="maxWidth")
    v-card(data-test="candidate-create-modal")
      v-card-title.headline Creating reservation
      v-card-text
        v-form(ref="form")
          v-row
            v-col(cols='12')
              v-text-field(label='Candidate Number'
                name="candidatenumber"
                :error="formErrors.hasError('candidateNumber')"
                :error-messages="formErrors.fields.candidateNumber"
                v-model="itemModel.candidateNumber"
                @change=""
              )
            v-col(cols='6')
              v-text-field(label='Nickname'
                data-test="c-create-nickname"
                v-model="itemModel.candidateName"
              )

            v-col(cols='6')
              v-text-field(label='Date of Birthday'
                name="bday"
                data-test="c-create-bthday"
                v-model="itemModel.candidateBirthday"
                :rules="[RULES.REQUIRED, RULES.DATE_INDIAN]"
                :error="formErrors.hasError('candidateBirthday')"
                :error-messages="formErrors.fields.candidateBirthday"
              )
            v-col(cols='6')
              v-text-field(label='First Name'
                name="name"
                data-test="c-create-first"
                v-model="itemModel.firstName"
                :error="formErrors.hasError('firstName')"
                :error-messages="formErrors.fields.firstName"
              )
            v-col(cols='6')
              v-text-field(label='Last Name'
                name="surname"
                data-test="c-create-last"
                v-model="itemModel.lastName"
                :error="formErrors.hasError('lastName')"
                :error-messages="formErrors.fields.lastName"
              )

            v-col(cols='6')
              filter-autocomplete(
                :getItems="getProductNames"
                v-model="itemModel.productName"
                item-text="name"
                item-value="name"
                label="Product name"
                data-test="c-create-product"
                :error="formErrors.hasError('productName')"
                :error-messages="formErrors.fields.productName"
              )
            v-col(cols='6')
              v-text-field(label='Email'
                data-test="c-create-email"
                :rules="[RULES.REQUIRED, RULES.EMAIL]"
                v-model="itemModel.candidateEmail"
              )

            v-col(cols='6')
              div.d-flex
                cbrUser(:itemModel="itemModel" v-model="itemModel.accountUsername" :product="selectedProduct" :formErrors="formErrors")

            v-col(cols='6')
                v-text-field(
                  label='Phone Number'
                  data-test="c-create-tel"
                  v-model="itemModel.candidateTel"
                  name="tel"
                  :error="formErrors.hasError('candidateTel')"
                  :error-messages="formErrors.fields.candidateTel"
                )

            v-col(cols="12")
                labels(:value="itemModel.labels" hide-exclude @changed="updateLabels")
                  template(v-slot:activator="{on}")
                    v-btn(color="primary" v-on="on" outlined)  Assign labels
                .d-flex.mt-2
                  span(v-for="(item, index) in itemModel.labels").mr-3
                    app-label(
                      :keyValue="item"
                      :label="labelsMap[item].label"
                      :isBtn="false"
                      :color="labelsMap[item].color"
                    )

            slot
      v-card-actions
          v-spacer
          v-btn(text @click="_failBtnClicked" large) close
          v-btn(color="primary" @click="_okBtnClicked" large data-test="c-create-btn") create
</template>

<script>
import { mapGetters, mapActions } from "vuex"
import { REQUIRED, EMAIL, DATE, DATE_INDIAN } from "@/util/validation-rules";

export default {
  props: {
    okBtnText: {
      type: String,
      default: 'Disagree'
    },
    failBtnText: {
      type: String,
      default: 'Agree'
    },
    formErrors: Object,
    descr: String,
    title: String,
    value: {
      type: Boolean,
    },
    maxWidth: {
      type: String,
      default: '340px'
    },
    itemModel: {
      type: Object,
      required: true
    }
  },


  data: () => ({
    RULES: {
      REQUIRED,
      EMAIL,
      DATE,
      DATE_INDIAN
    },
    formData: {
      name: null,
      birthday: null,
      email: null,
      account: null
    },
    defaultCBR: false
  }),

  computed: {
    ...mapGetters({
      crmCourseLocation: 'crmCourseCities/items',
      hasCrmCourseLocation: 'crmCourseCities/has',
      defaultCBRUser: 'cbrUser/default',
      cbrUsers: 'cbrUser/items',
      getProductBy: 'crmProductNames/getItemBy',
      customLabels: 'labels/items'
    }),

    selectedProduct() {
      if(!this.itemModel.productName) return null
      return this.getProductBy({field: 'name', value: this.itemModel.productName})
    },

    labelsMap() {
      let items = {}
      this.customLabels.forEach(item => {
        items[item.ID] = {
          label: item.name,
          color: item.color
        }
      })
      return items
    }
  },

  methods: {
    ...mapActions({
      getProductNames: 'crmProductNames/list',
      getCourseLocation: 'crmCourseCities/list',
    }),
    updateValue: function (value) {
      this.$emit('input', value)
    },

    _failBtnClicked() {
      this.$emit('failBtnClicked')
      this.updateValue(false)
    },

    _okBtnClicked() {
      this.$emit('okBtnClicked')
    },

    validate() {
      return this.$refs.form.validate()
    },

    setDefaultCBRUser() {
      if(!this.defaultCBR) {
        this.itemModel.accountUsername = null
        return
      }
      this.itemModel.accountUsername = this.defaultCBRUser.ID
    },

    updateLabels(labels) {
      this.itemModel.labels = labels
    }
  },

  watch: {
    data() {
      this.formData = this.data
    },

    value(val) {
      if(!val) {
        this.$refs.form.resetValidation()
        this.$emit('clearModel')
        this.defaultCBR = false
      }
    },

    'model.productName': function (newVal, oldVal) {
      if(!oldVal) return
      if(newVal.isExtended !== oldVal.isExtended) this.itemModel.productName = null
    }
  },

  components: {
    cbrUser: () => import('./CandidateCbrUser.vue'),
    labels: () => import('./CandidatesCustomLabels.vue'),
    filterAutocomplete: () => import('@/components/global/FilterAutocomplete.vue'),
    appLabel: () => import('@/components/global/Label.vue'),

  }
}
</script>
